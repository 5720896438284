@import "nlib/config";

.pageContent {
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  max-width: $maxContainerWidth;
  margin: 0 auto;
  padding: 0 $contentDefaultIndent * 2 $contentDefaultIndent * 2;
}
