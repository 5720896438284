@import "nlib/config";

.reconnectAccountModal {
  p {
    margin: 0;
    padding: 0 0.5rem 1rem 0;
    font-weight: 500;
    svg {
      margin-right: 0.25rem;
      font-size: 1rem;
    }
  }
  .dialogClassName {
    width: 40rem;
  }
  .statusCell {
    flex: 10;
    width: 10px;
    padding-left: 1.25rem;
    .text {
      color: $uiWarningColor;
      font-weight: 500;
      &.negative {
        color: $uiNegativeColor;
      }
    }
  }
  .businessNameCell {
    flex: 10;
    width: 10px;
  }
  .bankNameCell {
    flex: 10;
    width: 10px;
  }
  .businessNameCell,
  .bankNameCell {
    white-space: nowrap;
  }
  .actionCell {
    flex: none;
    width: 128px;
    padding-right: 0;
    .reconnectButton {
      padding: 0.25rem;
      border: 1px solid transparent;
      line-height: 1;
      svg {
        color: $uiWhiteColor;
        font-size: 1rem;
      }
    }
  }
}
