@import "nlib/config";

.globalNotifications {
  width: 100%;
  max-width: $maxContainerWidth;
  margin: 0 auto;
  padding: $contentDefaultIndent * 1.5 $contentDefaultIndent * 2 0;
}

[data-mobile] {
  .globalNotifications {
    padding: $contentDefaultIndent * 1.5 $contentDefaultIndent * 1.5 $contentDefaultIndent * 3;
  }
}
