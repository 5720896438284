@import "nlib/config";

.pageHeader {
  display: flex;
  margin-top: $contentDefaultIndent * 2;
  margin-bottom: $contentDefaultIndent * 2;
  .leftBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 2.5rem;
    .title {
      color: lighten($uiBlackColor, 10%);
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 1;
      a {
        color: inherit;
        text-decoration: none;
      }
      svg {
        font-size: 0.9em;
      }
    }
    .subtitle {
      color: $uiMediumDarkColor;
    }
  }
  .rightBlock {
    display: flex;
    flex: auto;
    justify-content: flex-end;
    padding-left: $contentDefaultIndent * 2;
  }
}

[data-mobile] {
  .pageHeader {
    width: 100%;
    max-width: $maxMobileContainerWidth;
    margin-right: auto;
    margin-left: auto;
  }
}
