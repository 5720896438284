@import "nlib/config";

.contactsPage {
  .pageHeader {
    margin-bottom: 0;
  }
  .filters {
    display: flex;
    flex: none;
    padding: $contentDefaultIndent * 2 0;
    background: $uiBackgroundColor;
    &.sticky {
      position: sticky;
      z-index: 1;
      top: 0;
    }
    .input {
      flex: 2;
      max-width: 24rem;
    }
    .select {
      flex: 1;
      max-width: 12rem;
      margin-left: $contentDefaultIndent;
    }
    .button {
      margin-left: $contentDefaultIndent;
    }
  }
  .emptyState {
    display: flex;
    flex: auto;
    padding: $contentDefaultIndent * 2;
    border: 1px solid $uiBorderColor;
    border-radius: $uiBorderRadius;
    background-color: $uiWhiteColor;
  }
}
