@import "nlib/config";

.sidebar {
  .preloader {
    flex: 1;
  }
  .label {
    margin-top: $contentDefaultIndent * 2;
    font-size: 0.75rem;
    font-weight: 500;
    line-height: 1;
  }
  .row {
    display: flex;
    margin-top: $contentDefaultIndent * 2;
    .col {
      flex: 1;
      max-width: 100%;
      + .col {
        margin-left: $contentDefaultIndent;
      }
    }
  }
}
