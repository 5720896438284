@import "nlib/config";

.table {
  [data-table-cell] {
    padding-right: $contentDefaultIndent;
    padding-left: $contentDefaultIndent;
  }
  .tableRow {
    cursor: pointer;
  }
  a {
    color: inherit;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  svg {
    font-size: 0.875rem;
  }
  .nameCell {
    flex: 15;
    width: 15px;
  }
  .subTypeCell {
    flex: 5;
    width: 5px;
  }
  .vatIdCell {
    flex: 10;
    width: 10px;
  }
  .phoneCell {
    flex: 10;
    width: 10px;
  }
  .email {
    flex: 10;
    width: 10px;
  }
  .addressCell {
    flex: 12;
    width: 12px;
  }
  .countryCell {
    flex: 3;
    justify-content: center;
    width: 3px;
  }
  .actionsCell {
    flex: none;
    width: 68px;
    padding-right: 0;
  }
}
.flagIcon {
  img {
    width: 1.5rem;
  }
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: $contentDefaultIndent * 2;
  .pagination {
    margin-right: $contentDefaultIndent * 2;
  }
}
